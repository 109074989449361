import React, { useEffect } from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import { trackGoal } from '../utilities/plausible';

const NewsletterAnmeldungPage = (props) => {
  const title = 'Newsletter Anmeldung';

  useEffect(() => {
    trackGoal('Newsletter: Subscribed');
  }, []);

  return (
    <Layout path={props.location.pathname}>
      <MetaData title={title} />

      <ContentSection>
        <h1>{title}</h1>

        <p className="mt-30">
          Vielen Dank, du hast dich erfolgreich für unseren Newsletter
          angemeldet.
        </p>
      </ContentSection>
    </Layout>
  );
};

export default NewsletterAnmeldungPage;
